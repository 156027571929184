import React, { useState } from "react";
import Logo from "../images/Logoblack.png";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
//Socials
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function Header() {
  const [mobile, setMobile] = useState(false);

  return (
    <>
      <div className="headerwrapper">
        <div className="mobilelogo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className={mobile ? "mobilenav" : "mobilenav closed"}>
          <img src={Logo} alt="Logo" />
          <a href="#main">über uns</a>
          <a href="#contact">kontakt</a>
          <a href="#contact">jetzt anfragen</a>
          <div className="socials">
            <InstagramIcon
              style={{ fontSize: "40px", color: "white", margin: "0 10px" }}
            />
            <TwitterIcon
              style={{ fontSize: "40px", color: "white", margin: "0 10px" }}
            />
            <FacebookIcon
              style={{ fontSize: "40px", color: "white", margin: "0 10px" }}
            />
          </div>
        </div>
        <div className="burger">
          {mobile ? (
            <div
              className="backicon menu"
              onClick={() => setMobile((prevState) => !prevState)}
            >
              <ClearIcon style={{ fontSize: "45px", color: "white" }} />
            </div>
          ) : (
            <div
              className="backicon menu"
              onClick={() => setMobile((prevState) => !prevState)}
            >
              <MenuIcon style={{ fontSize: "45px", color: "#517276" }} />
            </div>
          )}
        </div>
        <div className="navigation">
          <img src={Logo} alt="Logo" />
          <a href="#main">über uns</a>
          <a href="#contact">kontakt</a>
          <a href="#contact">jetzt anfragen</a>
        </div>
        <div className="headermain">
          <div className="headertitle">
            <span>soller wohnen</span>
            <h2>
              mit <span>Weitblick</span> <br /> Wohnraum schaffen
            </h2>
            <a href="#main">einblicke</a>
          </div>
        </div>
      </div>
    </>
  );
}
