import React from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Call from "../images/call.svg";

export default function Formcomponent({ open }) {
  return (
    <div className="contactformular">
      <p className="schliessenicon">
        schliessen{" "}
        <div className="slidericon form">
          <ClearRoundedIcon style={{ fontSize: "14px" }} />
        </div>
      </p>
      <div className="contactinformation">
        <h1>
          Wir freuen uns <br /> auf Ihren Anruf
        </h1>
        <a href="tel:+493451316890">
          <div className="contactbutton form">
            <img src={Call} alt="call" />
            <p>Rufen Sie uns an</p>
            <p> +49 345-1316890 </p>
          </div>
        </a>
        <label>
          E-mail
          <p>verwaltung@sollerwohnen.de</p>
        </label>
        <label>
          Adresse
          <p>
          An der Waisenhausmauer 12, <br /> 06108 Halle
          </p>
        </label>
      </div>
      <div className="contactform">
        <h1>Gerne stehen wir Ihnen für Rückfragen zur Verfügung.</h1>
        <p>Wir freuen uns auf Sie.</p>
        <form action="https://formspree.io/f/xgerbnwr" method="POST">
          <input required name="vorname" placeholder="Vorname *" />
          <input required name="nachname" placeholder="Nachname *" />
          <input
            type="email"
            required
            placeholder="E-mail Address *"
            name="email"
          />
          <input
            type="number"
            name="telefonnummer"
            required
            placeholder="Telefon *"
          />
          <textarea placeholder="Deine Nachricht.." rows="7" name="message" />
          <div className="accept">
            <input required type="checkbox" />
            <p>
              Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur
              Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die
              Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage
              gelöscht. Weitere Informationen zum Umgang mit Nutzerdaten finden
              Sie in unseren Datenschutzbedingungen.
            </p>
          </div>
          <button type="submit">schicken</button>
        </form>
      </div>
    </div>
  );
}
