import React from "react";
import Contact from "./components/Contact";
import ContentBlock from "./components/ContentBlock";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default function App() {
  return (
    <div className="pagewrapper" id="pagewrapper">
      <Header />
      <ContentBlock />
      <Contact />
      <Footer />
    </div>
  );
}
