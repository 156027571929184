import React, { useState } from "react";
import House from "../images/house.png";
import Housetwo from "../images/housetwo.jpg";
import Housethree from "../images/housethree.jpg";
//Icons
import Leafe from "../images/leafe.svg";
import Idk from "../images/idk.svg";
import Sun from "../images/Sun.svg";

import SwipeableViews from "react-swipeable-views";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIosRounded";

export default function ContentBlock() {
  const [index, setIndex] = useState(1);

  return (
    <div id="main" className="contentblock">
      <div className="contenttitle">
        <p>
          WARUM WIR DER RICHTIGE PARTNER <br className="brakemobile" /> FÜR SIE
          SIND
        </p>
        <h1>Was uns unterscheidet</h1>
      </div>
      <div className="contentmain">
        <div className="content1">
          <div className="content1block">
            <img src={Leafe} alt="leafe" />
            <h2>Nachhaltiger Wohnraum</h2>
            <p>
              Wir stellen die ökologischen Aspekte des Wohnens in den
              Mittelpunkt unseres Handelns
            </p>
          </div>
          <div className="content1block">
            <img src={Idk} alt="idk" />
            <h2>
              Wohnungen im urbanen und <br /> ländlichen Raum
            </h2>
            <p>
              Wir bieten Ihnen attraktiven Wohnraum in der Stadt und auf dem
              Land
            </p>
          </div>
          <div className="content1block">
            <img src={Sun} alt="sun" />
            <h2>Für ein bezahlbares und modernes Zuhause</h2>
            <p>
              Wir möchten unseren Teil dazu beitragen, bezahlbaren Wohnraum zu
              schaffen.
            </p>
          </div>
        </div>
        <div className="content2">
          {index > 2 ? (
            ""
          ) : (
            <div onClick={() => setIndex(index + 1)} className="slidericon">
              <span>
                <ArrowForwardIosIcon style={{ fontSize: "14px" }} />
              </span>
            </div>
          )}
          {index > 1 ? (
            <div
              onClick={() => setIndex(index - 1)}
              className="slidericon second"
            >
              <span>
                <ArrowForwardIosIcon
                  style={{ transform: "rotate(180deg)", fontSize: "14px" }}
                />
              </span>
            </div>
          ) : (
            ""
          )}
          <SwipeableViews index={index}>
            <a href={House}>
              <img src={House} alt="House" />
            </a>
            <img src={Housetwo} alt="House" />
            <img src={Housethree} alt="House" />
            <img src={House} alt="House" />
          </SwipeableViews>
        </div>
        <div className="content3">
          <p>
            In der Gemeinde Ismaning im Norden von München befindet sich der
            Geschäftssitz der Soller Wohnen GmbH.
          </p>
          <p>
            Zurückblickend auf eine langjährige Berufserfahrung mit Weitblick
            für innovative Investitionen bieten wir unseren Mietern nachhaltigen
            Wohnraum im urbanen und ländlichem Raum. <br /> <br /> Das kleine
            Team hat höchste Ansprüche an sich selbst und bietet seinen Kunden
            ein hohes Maß an Qualität, Kommunikation und Präzision, in allen
            Belangen Ihrer Immobilie. In der Mietverwaltung, Gewerbeverwaltung
            und Optimierung von Immobilien sind wir Ihr kompetenter
            Ansprechpartner. Erfahren Sie mehr über unsere Vielfalt an
            Leistungen und die Art und Weise, wie wir diese umsetzen. Wir freuen
            uns, schon bald von Ihnen zu hören.
          </p>
        </div>
      </div>
    </div>
  );
}
