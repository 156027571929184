import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Formcomponent from "./Formcomponent";
import Call from "../images/call.svg";
import Mail from "../images/mail.svg";

export default function Contact() {
  const [formular, setFormular] = useState(false);

  return (
    <div id="contact" className="contactwrapper">
      <Drawer anchor="right" open={formular} onClose={() => setFormular(false)}>
        <Formcomponent open={formular} />
      </Drawer>
      <div className="contacttext">
        <h1>Gerne stehen wir Ihnen für Rückfragen zur Verfügung.</h1>
        <p>Melden Sie sich einfach bei uns. Wir freuen uns auf Sie.</p>
      </div>
      <div className="contactbuttons">
        <a href="tel:+49 179 448 7509">
          <div className="contactbutton">
            <img src={Call} alt="call" />
            <p>Rufen Sie uns an</p>
            <p> +49 179 448 7509 </p>
          </div>
        </a>
        <div
          onClick={() => {
            setFormular((prevState) => !prevState);
          }}
          className="contactbutton"
        >
          <img src={Mail} alt="mail" />
          <p>
            Zum <br /> Kontaktformular
          </p>
        </div>
      </div>
    </div>
  );
}
